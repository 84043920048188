.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 999;
}

[class*='f--xx-small'], [class*='f-xx-small'] {
    font-size: xx-small;
}

[class*='f--x-small'], [class*='f-x-small'] {
    font-size: x-small;
}

[class*='f--smaller'], [class*='f-smaller'] {
    font-size: smaller;
}

[class*='f--small'], [class*='f-small'] {
    font-size: small;
}

[class*='f--medium'], [class*='f-medium'] {
    font-size: medium;
}

[class*='f--large'], [class*='f-large'] {
    font-size: large;
}

[class*='f--larger'], [class*='f-larger'] {
    font-size: larger;
}

[class*='f--x-large'], [class*='f-x-large'] {
    font-size: x-large;
}

[class*='f--xx-large'], [class*='f-xx-large'] {
    font-size: xx-large;
}

[class*='f--xxx-large'], [class*='f-xxx-large'] {
    font-size: xxx-large;
}

[class*='f--bold'], [class*='f-bold'] {
    font-weight: bold;
}

[class*='f--bolder'], [class*='f-bolder'] {
    font-weight: bolder;
}

[class*='f--italic'], [class*='f-italic'] {
    font-style: italic;
}

[class*='f--underline'], [class*='f-underline'] {
    text-decoration: underline;
}

[class*='f--strike'], [class*='f-strike'] {
    text-decoration: line-through;
}

[class*='f--uppercase'], [class*='f-uppercase'] {
    text-transform: uppercase;
}

[class*='f--lowercase'], [class*='f-lowercase'] {
    text-transform: lowercase;
}

[class*='f--capitalize'], [class*='f-capitalize'] {
    text-transform: capitalize;
}

[class*='a--center'], [class*='a-center'] {
    text-align: center;
}

[class*='a--right'], [class*='a-right'] {
    text-align: right;
}

[class*='a--left'], [class*='a-left'] {
    text-align: left;
}

[class*='f--danger--A'], .f--danger {
    color: red;
}

[class*='f--success--A'], .f--success {
    color: green;
}

.f--warning {
    color: #ee9d26;
}

[class*='f--info--A'] .f--info {
    color: blue;
}

[class*='t--ws-nw'] {
    white-space: nowrap;
}

[class*="--default"] {
    background: linear-gradient(45deg, #fff, #f8f8f8);
    color: #242424;
    border: #f8f8f8;
    border-width: 1px;
    border-style: solid;
    transition: all 0.3s ease-out;
}

[class*="--default"]:hover {
    background: linear-gradient(45deg, #242424, #242222);
    color: #fff;
}

[class*="--default"]:disabled {
    background: rgba(196, 183, 183, 0.788);
    color: #242424;
}

[class*="--default"]:disabled:hover {
    background: rgba(196, 183, 183, 0.788);
    transition: unset;
}

[class*="--primary--A"] {
    background: linear-gradient(45deg, #242424, #242222);
    color: #fff;
    border: #242424;
    border-width: 1px;
    border-style: solid;
    transition: all 0.3s ease-out;
}

[class*="--primary--A"]:hover {
    background: linear-gradient(45deg, #fff, #f8f8f8);
    color: #242424;
}

[class*="--primary--A"]:disabled {
    background: rgba(36, 36, 36, .4117647058823529);
    color: #fff;
}

[class*="--primary--A"]:disabled:hover {
    background: #24242469;
    transition: unset;
}

[class*='--info--A'] {
    background: linear-gradient(45deg, #516bbf, #25356b);
    color: #fff;
    border: #25356b;
    border-width: 1px;
    border-style: solid;
    transition: all 0.3s ease-out;
}

[class*='--info--A']:hover {
    background: linear-gradient(45deg, #25356b, #1a2c69);
}

[class*='--info--A']:disabled {
    background: #728bdc;
    transition: unset;
}

[class*='--danger--A'] {
    background: linear-gradient(45deg, #d4615e, #b33431);
    color: #fff;
    border: #bd3a37;
    border-width: 1px;
    border-style: solid;
    transition: all 0.3s ease-out;
}

[class*='--danger--A']:hover {
    background: linear-gradient(45deg, #bd3a37, #982825);
}

[class*='--danger--A']:disabled {
    background: #e47e7c;
    transition: none;
}

[class*='--danger--A']:disabled:hover {
    background: #e47e7c;
    transition: unset;
}

[class*='--warning--A'] {
    background: linear-gradient(45deg, #efb96a, #ea900c);
    color: #fff;
    border: #ea900c;
    border-width: 1px;
    border-style: solid;
    transition: all 0.3s ease-out;
}

[class*='--warning--A']:hover {
    background: linear-gradient(45deg, #ff9700, #e08805);
}

[class*='--warning--A']:disabled {
    background: #ecc283;
}

[class*='--warning--A']:disabled:hover {
    background: #ecc283;
    transition: unset;
}

[class*='--success--A'] {
    background: linear-gradient(45deg, #42dc48, #4bb34e);
    color: #fff;
    border: #4bb34e;
    border-width: 1px;
    border-style: solid;
    transition: all 0.3s ease-out;
}

[class*='--success--A']:hover {
    background: linear-gradient(45deg, #579c59, #287a2b);
}

[class*='--success--A']:disabled {
    background: #89cc8c;
}

[class*='--success--A']:disabled:hover {
    background: #89cc8c;
    transition: unset;
}

[class*="--special--A"] {
    background: linear-gradient(45deg, #b654de, #893ba9);
    color: #fff;
    border: #893ba9;
    border-width: 1px;
    border-style: solid;
    transition: all 0.3s ease-out;
}

[class*="--special--A"]:hover {
    background: linear-gradient(45deg, #973ebb, #6a2f82);
}

[class*='--special--A']:disabled {
    background: #cca5dc;
}

[class*='--special--A']:disabled:hover {
    background: #cca5dc;
    transition: unset;
}

.image-zoom {
    /* padding: 50px; */
    transition: transform .2s;
    /* Animation */
    /* width: 200px;
  height: 200px; */
    /* margin: 0 auto; */
}

.image-zoom:hover {
    transform: scale(1.5);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.hide-scroll-bar::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.field-text-area {
    overflow: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px 10px 0;
    cursor: pointer;
}

.A--pointer {
    cursor: pointer;
}

.location-pin-icon {
    margin: 5px;
}

.whatsapp-icon {
    margin: 5px;
}

.location-pin-icon svg {
    color: #ea4336;
    /* padding: 5px; */
    /* margin: 5px; */
}

.whatsapp-icon svg {
    color: #33a853;
    /* padding: 5px; */
    /* margin: 5px; */
}

.action-icon {
    font-size: 25px;
}

.action-icon svg {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .action-icon {
        font-size: 20px;
    }
}