.ccheckbox-container {
  display: table;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.25s;
}

.ccheckbox-container:hover {
  color: #2196F3;
}

.ccheckbox-container.-react-table-row-select {
  display: unset;
}

/* Hide the browser's default checkbox */
.ccheckbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ccheckbox-checkmark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  transition: 0.25s;
  border: 0.5px transparent;
}

/* On mouse-over, add a grey background color */
.ccheckbox-container:hover input~.ccheckbox-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked/indeterminate, add a blue background */
.ccheckbox-container input:checked~.ccheckbox-checkmark, .ccheckbox-container input:indeterminate~.ccheckbox-checkmark {
  background-color: #2196F3;
}

.ccheckbox-container input:disabled~.ccheckbox-checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ccheckbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ccheckbox-container input:checked~.ccheckbox-checkmark:after,
.ccheckbox-container input:indeterminate~.ccheckbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ccheckbox-container .ccheckbox-checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ccheckbox-container input:indeterminate~.ccheckbox-checkmark:after {
  border-width: 0 3px 0 0;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.ccheckbox-container-disabled {
  cursor: not-allowed;
  color: #777777;
}

.ccheckbox-container input:focus~.ccheckbox-checkmark {
  border: 0.5px dashed;
}