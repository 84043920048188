.react-table-component {
    width: 100%
}

.table-filters {
    display: flex;
    align-items: center;
    justify-content: center;
}

.global-filter {
    /* float: left; */
    display: inline-block;
}

.column-filter {
    display: inline-block;
}

.pagination-pagedata, .pagenation-navigation, .global-filter-toggel {
    /* margin: auto;
    justify-content: center;
    align-items: center;
    display: flex; */
    text-align: center;
}


.react-table-show {
    margin: 10px 1% 1%;
    box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
    max-height: 70vh;
    /* border: 5px transparent; */
    border-radius: 5px;
}

.react-table-show::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.react-table {
    border-radius: 5px;
    font-size: 20px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.react-table th, .react-table td {
    text-align: center;
    padding: 8px;
    border-right: 1px solid #f8f8f8;
    max-width: 150px;
    white-space: nowrap;
    overflow-x: auto;
}

.react-table th::-webkit-scrollbar, .react-table td::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* .react-table th:last-child {
    z-index: 100;
} */

/* .react-table tr td:last-child {
    z-index: 50;
} */

.react-table th:last-child, .react-table tr td:last-child {
    border-right: none;
    right: 0;
    position: sticky;
    border-left: 1px solid #f8f8f8;
}

.react-table tr th:first-child, .react-table tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 55;
}

.react-table thead th {
    color: #fff;
    background-color: #524f4f;
    position: sticky;
    top: 0;
    z-index: 50;
}

.react-table tbody tr:nth-child(even) {
    background-color: #deedef;
}

.react-table tbody tr:nth-child(odd) {
    background-color: #cde0e2;
}

.react-table tbody tr:hover td {
    background-color: #738d90;
}

/* .react-table tbody tr  */
td.react-table-td-checkbox-checked~td {
    background-color: #8cb0b4;
}

/* .react-table-cell-svg-icons a {
    text-decoration: none;
    padding: 5px;
    margin: 5px;
} */

.react-table tbody tr:hover .action-icon svg {
    color: white;
}

@media screen and (max-width: 600px) {
    .react-table {
        display: block;
        width: 100%;
    }

    .react-table-show::before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .react-table thead, .react-table tbody, .react-table thead th {
        display: block;
    }

    .react-table thead th:last-child {
        border-bottom: none;
    }

    .react-table thead {
        float: left;
    }

    .react-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .react-table th, .react-table td {
        min-height: 60px;
        max-height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: auto;
        overflow-y: auto;
        max-width: 100px;
        white-space: nowrap;
    }

    .react-table thead th {
        border-bottom: 1px solid #f7f7f9;
        text-align: left;
    }

    .react-table tr {
        display: table-cell;
    }

    .react-table tr td {
        border-right: 1px solid #E6E4E4;
        border-bottom: 1px solid #E6E4E4;
    }

    .react-table tbody td {
        display: block;
        text-align: center;
    }

    .react-table th:last-child, .react-table tr td:last-child {
        border-bottom: none;
        border-left: none;
        border-right: 1px solid #E6E4E4;
        z-index: unset;
    }
}