:root {
    --primary: #fff
}

[class*="btn"] {
    padding: 8px 20px;
    border-radius: 2px;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    margin: 4px 10px;
    box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
}

[class*='btn']:focus {
    border: 1px solid white;
}

[class*="btn"]:disabled {
    border-style: dashed;
    cursor: not-allowed;
    box-shadow: unset;
}

[class*="btn"]:disabled:hover {
    transition: none;
}

[class*="btn"] svg {
    vertical-align: middle;
}

[class*="btn"]:disabled svg {
    color: rgba(128, 128, 128, 0.781);
}

[class*="btn--default"] {
    border: 1px solid #242424;
    border-radius: 4px;
}

[class*="btn--primary"] {
    padding: 8px 20px;
    border: 1px solid #242424;
    border-radius: 5px;
    /* transition: all 0.3s ease-out; */
}

[class*='btn--info'] {
    border: 1px solid #25356b;
    border-radius: 4px;
}

[class*='btn--danger'] {
    border: 1px solid #bd3a37;
    border-radius: 4px;
}

[class*='btn--warning'] {
    border: 1px solid #f19b1e;
    border-radius: 4px;
}

[class*='btn--success'] {
    border: 1px solid #04661c;
    border-radius: 4px;
}

[class*="btn--special"] {
    border: 1px solid #713b88;
    border-radius: 4px;
}

[class*="btn--small"] {
    padding: 4px 14px;
    font-size: 16px;
}

[class*="btn--medium"] {
    padding: 8px 20px;
    font-size: 20px;
}

[class*="btn--large"] {
    padding: 12px 26px;
    font-size: 20px;
}

[class*="btn--small--round"], [class*="btn--medium--round"] {
    border-radius: 50%;
    padding: 5px;
    height: 40px;
    width: 40px;
}