.mrg-btm-30px {
    margin-bottom: 30px;
}

.form-field {
    position: relative;
    margin: 20px 0px 30px;
}

.form-field input::-webkit-input-placeholder,
.form-field textarea::-webkit-input-placeholder {
    color: transparent;
}

.form-field input, .form-field select, .form-field textarea {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    border: none;
    border-bottom: 2px solid #999;
}

.form-field input:disabled, .form-field select:disabled, .form-field textarea:disabled {
    cursor: not-allowed;
}

.form-field input.inp-box-err,
.form-field textarea.inp-box-err,
.form-field select.slt-box-err {
    color: #db413d;
    border-bottom: 2px solid #db413d;
}

.form-field input.inp-box-success:not([value=""]),
.form-field textarea.inp-box-success:not([value=""]),
.form-field select.slt-box-success:not([value='']) {
    color: #1c793a;
    border-bottom: 2px solid #1c793a;
}

.form-checkbox {
    /* padding-left: 10px; */
    padding-bottom: 5px;
}

.form-checkbox input[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin: 5px;
    vertical-align: middle;
}

.form-field label {
    position: absolute;
    pointer-events: none;
}

.form-field input[value=""]~label,
.form-field textarea[value=""]~label {
    /* position: absolute; */
    top: 5px;
    /* pointer-events: none; */
    left: 0;
    color: #999;
    transition: .5s;
    padding-left: 10px;
}

.form-field input:not([value=""])~label,
.form-field textarea:not([value=""])~label,
.form-field select~label {
    top: -25px;
    left: 0;
    color: #496af7;
    font-size: 20px;
    padding: 0;
}

.form-field select:focus~label {
    font-weight: bold;
}

.form-field input:focus~label,
.form-field textarea:focus~label {
    top: -25px;
    left: 0;
    color: #496af7;
    font-size: 20px;
    font-weight: bold;
    padding: 0;
}

.form-field input~label.form-label-err,
.form-field textarea~label.form-label-err,
.form-field select~label.form-label-err {
    color: #db413d;
}

.form-field input:not([value=""])~label.form-label-success,
.form-field input:focus~label.form-label-success,
.form-field textarea:not([value=""])~label.form-label-success,
.form-field textarea:focus~label.form-label-success,
.form-field select:not([value=""])~label.form-label-success {
    color: #1c793a;
}

.form-field .form-field-error-div {
    color: #db413d;
    font-size: 15px;
    padding-left: 5px;
    animation: fadeIn 0.5s;
}

/* .form-field select~label {
    display: none;
} */

@media only screen and (min-width: 960px) {
    .form-field {
        display: table;
        position: unset;
        /* margin: unset; */
        margin-top: 0px;
        margin-bottom: 10px;
        width: 600px;
    }

    .form-field input::-webkit-input-placeholder,
    .form-field textarea::-webkit-input-placeholder {
        color: #999;
    }

    .form-field input[value=""]~label,
    .form-field input:not([value=""])~label,
    .form-field textarea[value=""]~label,
    .form-field textarea:not([value=""])~label,
    .form-field select~label {
        position: unset;
        /* color: #496af7; */
        color: black;
        padding: 0;
    }

    .form-field label {
        position: unset;
        width: 200px;
        text-align: right;
        padding: unset;
        color: black;
        transition: .5s;
    }

    .form-field input,
    .form-field textarea,
    .form-field select {
        width: 50%;
        float: right;
    }

    .form-field input:focus~label,
    .form-field textarea:focus~label,
    .form-field select:focus~label {
        padding: 10px;
        color: #496af7;
    }

    .form-field input~label.form-label-err,
    .form-field textarea~label.form-label-err,
    .form-field select~label.form-label-err {
        color: #db413d;
    }

    .form-field .form-field-error-div {
        padding-left: 50%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}