.menubar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 90;
}

.menubar-scrolled {
    top: -50px;
}

.menubar-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    width: 100%;
}

.menu-bar {
    display: grid;
    grid-template-columns: repeat(10, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    justify-content: end;
    margin-right: 2rem;
}

.menu-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border: 2px solid transparent;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease;
}

.menu-links.active {
    color: #000;
    background: #fff;
}

.menu-links:hover {
    border-bottom: 2px solid #fff;
    /* transition: all 0.2s ease-out; */
}

.menu-links.active:hover {
    color: #fff;
    background: none;
    border: 2px solid;
}

.menu-open-icon {
    color: #fff;
}

.menubar-icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .menubar {
        height: 20px;
        position: relative;
    }

    .navbaritems {
        position: relative;
    }

    .menu-bar {
        display: none;
    }

    .menu-bar.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 10vh;
        position: absolute;
        top: 19px;
    }

    .menu-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }

    .menu-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .menu-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menubar-icon {
        display: block;
        position: absolute;
        /* top: 0; */
        right: 10px;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu-close-icon {
        color: #fff;
        font-size: 2rem;
    }
}