.sidebar {
    position: sticky;
    top: 0;
    float: left;
}

.sidebar-header {
    display: none;
}

.sidebar-header svg {
    vertical-align: middle;
}

.sidebar-icons {
    margin-left: 2rem;
    font-size: 20px;
    background: none;
    text-decoration: none;
    color: #fff;
    align-items: center;
    padding: 0 16px;
}

.sidebar-icons span {
    margin-left: 10px;
}

.sidebar-container {
    position: relative;
}

.sidebar-menu {
    background-color: #242424;
    width: 250px;
    height: 100vh;
    /* height: calc((100vh) - 60px); */
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;
    transition: 850ms;
    overflow-y: auto;
    /* display: none; */
}

.sidebar-menu-items {
    width: 100%;
}

.sidebar-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding: 8px 10px 8px 16px; */
    list-style: none;
    min-height: 60px;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: wrap;
}

/* .sidebar-item a, .sidebar-item  */
.sidebar-item-title {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    /* border-radius: 4px; */
    overflow-x: auto;
    white-space: nowrap;
    cursor: pointer;
}

.sidebar-item-title.active {
    background-color: #1f70ce;
}

.sidebar-item-title:hover, .sidebar-item-title.active:hover {
    background-color: #1a83ff;
}

.sidebar-item::-webkit-scrollbar, .sidebar-item a::-webkit-scrollbar {
    display: none;
}

.sidebar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* .sidebar-item-title span {
    margin-left: 16px;
} */

.sub-menu-container {
    width: 100%;
    margin-left: 10px;
    display: none;
}

.sub-menu-container.active {
    display: flex;
}

.sub-menu-items {
    width: 100%;
    margin-left: 16px;
}

.sub-menu-links {
    text-decoration: none;
    font-size: 18px;
    padding: 5px 5px 5px 16px;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    /* height: 100%; */
    color: #f8f8f8;
    border-left: 4px solid rgb(95, 161, 236);
    margin-bottom: 4px;
    cursor: pointer;
}

.sub-menu-links.active {
    background: rgb(61, 131, 211);
}

.sub-menu-links:hover, .sub-menu-links.active:hover {
    background-color: rgb(95, 161, 236);
}

.flex-box-break {
    flex-basis: 100%;
    height: 0;
}

/* width */
[class*="custom-scrollbar"]::-webkit-scrollbar {
    width: 5px;
}

/* Track */
[class*="custom-scrollbar"]::-webkit-scrollbar-track {
    /* background: #f1f1f1;
    border: 4px solid transparent;
    background-clip: content-box;   THIS IS IMPORTANT */
    background: #f1f1f1;
    border: 4px solid transparent;
    background-clip: content-box;
    border-radius: 50px;
}

/* Handle */
[class*="custom-scrollbar"]::-webkit-scrollbar-thumb {
    /* background: #888;
    border: 1px solid rgb(0,0,0); */
    background: #888;
    border: 1px transparent;
    border-radius: 50px;
}

/* Handle on hover */
[class*="custom-scrollbar"]::-webkit-scrollbar-thumb:hover {
    background: #555;
    width: 10px;
}

@media screen and (max-width: 960px) {
    .sidebar {
        position: unset;
        float: none;
    }

    .sidebar-header {
        background: #242424;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    /* .sidebar-icons {
        margin-left: 2rem;
        font-size: 20px;
        background: none;
    } */
    .sidebar-menu {
        position: absolute;
        display: none;
    }

    .sidebar-menu.active {
        background: #242424;
        width: 250px;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: 350ms;
        z-index: 100;
    }
}

@media screen and (max-width: 600px) {
    .sidebar-menu.active {
        width: 100%;
    }
}