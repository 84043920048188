.loading-spinner-component {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    user-select: none;
}

.loading-spinner {
    width: calc((50vw + 50vh) / 3);
    height: calc((50vw + 50vh) / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc((50vw + 50vh) / 15);
    overflow: hidden;
    position: relative;
    animation: text-color 2s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
    pointer-events: none;
}

.spinner-sector {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 15px solid transparent;
    mix-blend-mode: overlay;
    /* animation: rotate 2s ease-in-out infinite; */
    animation: rotate var(--duration) var(--timing) infinite;
}

.spinner-sector-red {
    border-top-color: lightcoral;
    --duration: 1.5s;
    --timing: ease-in-out;
}

.spinner-sector-green {
    border-left-color: lightseagreen;
    --duration: 1s;
    --timing: ease-in;
}

.spinner-sector-blue {
    border-right-color: mediumvioletred;
    --duration: 2s;
    --timing: ease-out;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes text-color {
    0% {
        color: rgba(0, 0, 0, 1);
    }

    50% {
        color: rgba(0, 0, 0, .5);
    }

    100% {
        color: rgba(0, 0, 0, .1);
    }
}