* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

body {
    background: linear-gradient(#b2cfd3, #d4e0e0);
    min-height: 100vh;
}

[class*="content-page"] {
    padding: 10px 1% 1%;
    margin: auto;
    font-size: 125%;
    line-height: 1.6;
    background: linear-gradient(#b2cfd3, #d4e0e0);
    min-height: 100vh;
}

button {
    background-color: #37a73b;
    /* border: none; */
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 2px 1px;
    cursor: pointer;
    border-style: solid;
    border-color: rgb(4, 102, 28);
    border-radius: 6px;
}

button:disabled {
    background-color: #8fd491;
    border-style: dotted;
    cursor: not-allowed;
}

/* input[type=text], input[type=number] {
    padding: 5px 10px;
    margin: 2px 1px;
    box-sizing: border-box;
    font-size: 75%;
}
*/

/* input[type="checkbox"] {
    height: 15px;
    width: 15px;
    margin: 5px;
}  */

input:disabled {
    cursor: not-allowed;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    padding: 5px 10px;
    margin: 2px 1px;
    box-sizing: border-box;
    font-size: 75%;
}

button[class*="delete"] {
    background-color: rgb(241, 90, 90);
    border-color: rgb(255, 0, 0);
}

button[class*="delete"]:disabled {
    background-color: rgb(241, 136, 136);
}

fieldset {
    border: 2px solid;
    padding: 10px;
    margin: 15px;
    border-radius: 15px;
}

fieldset legend {
    font-weight: bolder;
    font-size: larger;
    margin-left: 20px;
}

/* basic modal css */

.customOverlay {
    background: #798888b0;
    /*  #d4e0e09e; */
    border: none;
    /* box-shadow: inset 0px 35px 50px rgb(0 0 0 / 20%); */
}

.customModal {
    background: linear-gradient(#b2cfd3, #d4e0e0);
    border: none;
    box-shadow: 0px 35px 50px rgb(0 0 0 / 20%);
    min-width: 80vw;
    max-width: 90vw;
    height: 90vh;
}

.full-loading-transparent {
    background: transparent;
    box-shadow: none;
}

.rotate-any {
    animation: rotateany 2s ease-in infinite;
}

@keyframes rotateany {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}