[class*='bsdivs']{
    text-align: left;
}

[class*='div--xx--small']{
    font-size: xx-small;
}

[class*='div--x--small']{
    font-size: x-small;
}

[class*='div--smaller']{
    font-size: smaller;
}

[class*='div--small']{
    font-size: small;
}

[class*='div--medium']{
    font-size: medium;
}

[class*='div--large']{
    font-size: large;
}

[class*='div--larger']{
    font-size: larger;
}

[class*='div--x--large']{
    font-size: x-large;
}

[class*='div--xx--large']{
    font-size: xx-large;
}

[class*='div--bold']{
    font-weight: bold;
}

[class*='div--bolder']{
    font-weight: bolder;
}

[class*='div--italic']{
    font-style: italic;
}

[class*='div--underline']{
    text-decoration: underline;
}

[class*='div--strike']{
    text-decoration: line-through;
}

[class*='div--text-center']{
    text-align: center;
}

[class*='div--text-right']{
    text-align: right;
}

[class*='div--pre--line']{
    white-space: pre-line;
}
