[class*="navbar-head"] {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    transition: top 0.3s;
}

.navbar-head-scrolled {
    top: -80px;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.gk-angadi-logo {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 20px;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease-out;
}

.nav-links.active {
    border-bottom: 4px solid #fff;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    /* transition: all 0.3s ease-out; */
}

.menu-open-icon {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.nav-links-logout {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
    justify-self: end;
}

.nav-links-logout:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .navbaritems {
        position: relative;
    }

    .nav-menu {
        display: none;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 10vh;
        position: absolute;
        top: 80px;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }

    .nav-links.active {
        background: #fff;
        color: #000;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .nav-links.active:hover {
        background: none;
        color: #fff;
        border: 2px solid #fff
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu-close-icon {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }

    .nav-links-logout {
        display: none;
    }
}